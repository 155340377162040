import React, { useState, useEffect } from 'react';
import '../About.css';

function About() {
    const [globalMarketCap, setGlobalMarketCap] = useState('');

    useEffect(() => {
        // Example CORS Proxy URL - For development purposes
        const corsProxyUrl = 'https://cors-anywhere.herokuapp.com/';
        const targetUrl = 'https://api.coingecko.com/api/v3/global';

        const fetchMarketCap = async () => {
            try {
                const response = await fetch(`${corsProxyUrl}${targetUrl}`, {
                    method: 'GET',
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                    },
                });
                
                if (!response.ok) {
                    throw new Error(`Network response was not ok, status: ${response.status}`);
                }
                const data = await response.json();
                setGlobalMarketCap(data.data.total_market_cap.usd.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                }));
            } catch (error) {
                console.error("Failed to fetch market cap data:", error);
            }
        };

        fetchMarketCap();
    }, []);

    return (
        <div className="min-h-screen bg-gradient-to-r from-violet-500 to-fuchsia-800 text-white">
            <div className="px-4 py-5 mx-auto sm:p-6 md:p-8 lg:p-10 xl:px-20 max-w-screen-xl">
                <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl mt-8 font-bold text-center mb-8">Donating Made Digital</h1>
                <div className="space-y-2 sm:space-y-3 md:space-y-4">
                    <p className="text-base sm:text-lg md:text-xl">
                        In a world where the global market cap of cryptocurrency soars into the trillions...
                    </p>
                    {globalMarketCap && (
                        <div className="text-center mb-4">
                            <p className="text-lg sm:text-xl md:text-2xl">Global Cryptocurrency Market Capitalization:</p>
                            <p className="font-bold text-2xl sm:text-3xl md:text-4xl p-3">{globalMarketCap}</p>
                        </div>
                    )}
                    <p className="text-base sm:text-lg md:text-xl">
                        Our mission is to empower donors and non-profits alike, ensuring that the vast wealth held in digital currencies can be harnessed to make a real difference in the world. This isn't just about embracing the digital age; it's about opening a new avenue for generosity, enabling anyone, anywhere, to contribute to the causes they believe in with the assets they own.
                    </p>
                    <h2 className="text-lg sm:text-xl md:text-2xl font-semibold">Empowering Transparency and Security</h2>
                    <p className="text-base sm:text-lg md:text-xl">
                        At the heart of our service is the innovative use of smart contracts. These digital agreements execute automatically when their conditions are met, ensuring donations reach their intended recipients without any intermediary. This not only maximizes the impact of every donation but also provides an unprecedented level of transparency and security. Donors can see exactly where their contributions are going, and non-profits can confidently assert their legitimacy, fostering a culture of trust and accountability.
                    </p>
                    <h2 className="text-lg sm:text-xl md:text-2xl font-semibold">Cryptocurrency: The Future of Giving</h2>
                    <p className="text-base sm:text-lg md:text-xl">
                        Recognizing the growing prominence of digital currencies, we offer a legitimate and secure pathway for using cryptocurrency as a means to donate. This approach not only caters to the modern donor, who may prefer digital currency over traditional cash, but also breaks down geographical barriers, allowing anyone with internet access to support causes they believe in.
                    </p>
                    <ul className="list-disc list-inside text-base sm:text-lg space-y-2 sm:space-y-3">
                        <li>Global Accessibility: Our platform enables global participation in philanthropy, allowing donors from any part of the world to contribute to non-profits using cryptocurrencies.</li>
                        <li>Efficiency and Speed: Cryptocurrency transactions on our platform are fast, ensuring timely support for non-profit initiatives and immediate impact.</li>
                        <li>Privacy with Integrity: We respect donor privacy by offering anonymity options, while still maintaining the integrity and transparency of the donation process through the blockchain ledger.</li>
                        <li>Expanding Non-Profit Reach: By accepting cryptocurrencies, non-profits can tap into a new demographic of donors and potentially increase their funding through the appreciation of digital assets.</li>
                    </ul>
                    <h2 className="text-lg sm:text-xl md:text-2xl font-semibold">Our Vision</h2>
                    <p className="text-base sm:text-lg md:text-xl">
                        We envision a world where technology bridges the gap between generosity and impact. Our platform is more than just a tool for transactions; it's a catalyst for change, driving the future of philanthropy towards a more inclusive, effective, and transparent practice.
                    </p>
                    <p className="text-base sm:text-lg md:text-xl">
                        Whether you're a non-profit looking to embrace the digital age or a donor seeking a trustworthy and innovative way to support meaningful causes, we invite you to join our platform. Together, we can unlock the potential of technology to create a lasting impact on the world.
                    </p>
                    <p className="text-base sm:text-lg md:text-xl font-medium mt-8">
                        Let's pave the way for a new era of charitable giving, where every donation is a step towards a better future.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default About;