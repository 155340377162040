import React from 'react';
import { SiX, SiDiscord, SiLinkedin } from 'react-icons/si';
import Lottie from 'react-lottie';
import phone from '../animations/phone.json';
import BackgroundVector from './BackgroundVector';

function Home() {

    // Lottie animation options
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: phone,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <div className="relative min-h-screen flex flex-col justify-between bg-gradient-to-r from-violet-700 via-zinc- to-zinc-800">
            <BackgroundVector />
            <div className="pt-12 sm:pt-24 md:pt-36 px-4 sm:px-10 md:px-20 lg:px-32 xl:px-40 z-10 relative">
                <h2 className="text-white text-4xl sm:text-6xl md:text-7xl lg:text-8xl xl:text-9xl font-normal mb-5 sm:mb-8 md:mb-10">pledge with a purpose</h2>
                <p className="text-white text-2xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-6xl mb-2">From blockchain to benevolence:</p>
                <p className="text-white text-2xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-6xl mb-10">Empower non-profits effortlessly</p>
                {/* Social icons aligned left */}
                <div className="flex justify-start gap-4 mt-6 sm:mt-8 md:mt-10 text-white">
                    <a href="https://twitter.com/pledgerpro" className="hover:text-black" target="_blank" rel="noopener noreferrer">
                        <SiX size={48} />
                    </a>
                    <a href="https://discord.gg/Mkr78Q6N" className="hover:text-violet-300" target="_blank" rel="noopener noreferrer">
                        <SiDiscord size={48} />
                    </a>
                    <a href="https://www.linkedin.com/company/pledgerpro/" className="hover:text-blue-400" target="_blank" rel="noopener noreferrer">
                        <SiLinkedin size={48} />
                    </a>
                </div>
                {/* Phone animation aligned with the content */}
                <div className="mt-10">
                    <Lottie options={defaultOptions} height={500} width={500} />
                </div>
            </div>
        </div>
    );
}

export default Home;