import React from 'react';
import Lottie from 'react-lottie';
import underMaintAnimation from '../animations/under-maint.json'; // Ensure the path is correct

function WorkInProgress() {
    // Lottie animation options
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: underMaintAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <div className="bg-gradient-to-r from-violet-500 to-fuchsia-800 text-white min-h-screen flex items-center justify-center">
            <div className="text-center p-4 mx-auto" style={{ maxWidth: '800px' }}>
                <h1 className="text-6xl font-bold mb-10">Work in Progress</h1>
                {/* Adjusted Lottie Animation size */}
                <div className="mx-auto mb-4" style={{ width: '100%', maxWidth: '600px', height: '400px' }}> {/* Increased maxWidth and height */}
                    <Lottie options={defaultOptions} />
                </div>
                <p className="text-2xl mt-10">
                    We're currently working hard on new features and improvements. This feature should be available shortly. Stay tuned for updates and thank you for your patience!
                </p>
            </div>
        </div>
    );
}

export default WorkInProgress;