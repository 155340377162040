import React from 'react';
import { SiX, SiDiscord, SiLinkedin } from 'react-icons/si';
import Lottie from 'react-lottie';
import social from '../animations/social.json';

function Community() {
    // Lottie options
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: social,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-violet-500 to-fuchsia-800 py-10 lg:py-20">
            <div className="text-center w-full px-4 sm:px-10 md:px-20 lg:px-32 xl:px-40">
                <h2 className="text-white text-4xl sm:text-5xl md:text-6xl lg:text-6xl xl:text-6xl 2xl:text-6xl font-bold mb-8 lg:mb-12">Building Our Community</h2>
                {/* Animation container with explicit alignment */}
                <div className="flex justify-center items-center w-full" style={{ maxWidth: '500px', margin: '0 auto', height: '300px' }}>
                    <Lottie options={defaultOptions} height={300} width={300} />
                </div>
                <p className="text-white text-xl sm:text-2xl md:text-3xl lg:text-3xl xl:text-3xl 2xl:text-3xl mt-8 lg:mt-12 mb-12">We're hard at work building the application. For updates and to stay in the loop, follow us on social media.</p>
                <div className="flex gap-8 sm:gap-12 md:gap-16 lg:gap-16 xl:gap-16 2xl:gap-16 justify-center">
                    <a href="https://twitter.com/pledgerpro" className="text-white hover:text-black" target="_blank" rel="noopener noreferrer">
                        <SiX size={32} className="md:size-[48px]" />
                    </a>
                    <a href="https://discord.gg/Mkr78Q6N" className="text-white hover:text-violet-300" target="_blank" rel="noopener noreferrer">
                        <SiDiscord size={32} className="md:size-[48px]" />
                    </a>
                    <a href="https://www.linkedin.com/company/pledgerpro/" className="text-white hover:text-blue-400" target="_blank" rel="noopener noreferrer">
                        <SiLinkedin size={32} className="md:size-[48px]" />
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Community;