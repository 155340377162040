import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="bg-black text-gray-400 text-xs sm:text-sm p-4 md:p-6 flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
      <span>
        Media Inquiries for Pledger - Contact 
        <a href="mailto:media@pledger.pro" className="text-white pl-1">
          media@pledger.pro
        </a>
      </span>
      <nav className="flex justify-center">
        <Link to="/security" className="hover:text-gray-300 pr-4">Security</Link>
        <Link to="/privacy" className="hover:text-gray-300">Privacy</Link>
      </nav>
    </footer>
  );
}

export default Footer;