import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import About from './components/About';
import Community from './components/Community';
import Blog from './components/Blog';
import Jobs from './components/Jobs';
import WorkInProgress from './components/WorkInProgress';
import Home from './components/Home';

function App() {
  return (
    <Router>
      <div className="flex flex-col min-h-screen relative overflow-hidden">
        <Header />
        <div className="flex-grow relative overflow-hidden">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/community" element={<Community />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/jobs" element={<Jobs />} />
            <Route path="/signup" element={<WorkInProgress />} />
            <Route path="/donate" element={<WorkInProgress />} />
            <Route path="/security" element={<WorkInProgress />} />
            <Route path="/privacy" element={<WorkInProgress />} />
            
          </Routes>
          <div className="relative z-10">
          </div>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
