import { ReactComponent as BackgroundVectorSVG } from "../svg/background-vector.svg";

const BackgroundVector = () => {
  return (
<div className="absolute bottom-0 right-0 w-full h-auto">
  <BackgroundVectorSVG className="w-full h-full" />
</div>
  );
};

export default BackgroundVector;