import React from 'react';
import Lottie from 'react-lottie';
import teamAnimation from '../animations/team.json';

function Jobs() {
    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: teamAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-violet-500 to-fuchsia-800 text-white py-16">
            <div className="w-full max-w-4xl text-center px-4">
                <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold mb-8">Join Our Team</h1>
                <div className="flex justify-center my-8">
                    {/* Make Lottie animation responsive and centered */}
                    <Lottie options={defaultOptions} width="100%" maxWidth={500} />
                </div>
                <p className="text-lg sm:text-xl md:text-2xl mb-5">
                    At Pledger, we're driven by the potential of cryptocurrency in philanthropy. As a growing startup, we're seeking passionate individuals ready to shape the future of digital donations.
                </p>
                <p className="text-lg sm:text-xl md:text-2xl mb-5">
                    In these early stages, we're building a core team willing to invest their skills for future rewards. We're especially looking for talents in development, marketing, and business development.
                </p>
                <p className="text-lg sm:text-xl md:text-2xl mb-10">
                    If you're interested in making a difference and being part of our journey from the ground up, we'd love to connect. Email <a href="mailto:jim@pledger.pro" className="text-blue-500 underline">jim@pledger.pro</a> with your resume or portfolio. Together, let's revolutionize philanthropy.
                </p>
            </div>
        </div>
    );
}

export default Jobs;