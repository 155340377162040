import React from 'react';
import BlogPost from './BlogPost';

const posts = [
    {
        id: 1,
        title: "Site Launched!",
        date: "March 15, 2024",
        content: "We are thrilled to announce the launch of our site! Development is ongoing, and we're hard at work on introducing the actual donation functionality. To stay updated with our progress and join the conversation, follow us on Twitter, join our Discord community, or connect with us on LinkedIn. We look forward to growing with you and making a meaningful impact together!"
    },
    // Future posts can be added here
];

function Blog() {
    return (
        <div className="bg-gradient-to-r from-violet-500 to-fuchsia-800 text-white min-h-screen flex flex-col items-center justify-center px-4 md:px-10 lg:px-32 xl:px-40">
            <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-6xl xl:text-6xl font-bold mb-12">Our Blog</h1>
            {posts.map(post => (
                <BlogPost key={post.id} title={post.title} date={post.date} content={post.content} />
            ))}
        </div>
    );
}

export default Blog;