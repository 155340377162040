import React from 'react';

function BlogPost({ title, date, content }) {
    return (
        <div className="bg-white text-black m-4 p-4 sm:p-6 md:p-8 rounded-lg shadow-lg">
            <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-2">{title}</h2>
            <p className="text-sm sm:text-md md:text-lg text-gray-500">{date}</p>
            <div className="mt-4 text-md sm:text-lg md:text-xl">
                {content}
            </div>
        </div>
    );
}

export default BlogPost;