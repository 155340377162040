import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { HiMenu, HiX, HiHome } from 'react-icons/hi'; // Import HiHome for the home icon
import logo from '../images/pledger-logo-white.png';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header className="bg-black text-white flex items-center justify-between font-sans p-2 sm:p-4 lg:p-6 relative">
      <div className="flex items-center shrink-0">
        {/* Display Home icon and Pledged text for smaller screens */}
        <Link to="/" className="flex items-center">
          <div className="md:hidden flex items-center">
            <HiHome className="h-8 w-8 sm:h-10 sm:w-10 text-white" />
            <span className="text-md sm:text-lg ml-2">pledger</span>
          </div>
          {/* Display the full logo for larger screens */}
          <img src={logo} alt="Pledger logo" className="hidden md:block h-16 sm:h-18 md:h-20 lg:h-24 xl:h-28" />
        </Link>
      </div>
      <div className="md:hidden z-30">
        <button onClick={() => setIsMenuOpen(!isMenuOpen)} aria-label="Toggle menu" className="p-2">
          {isMenuOpen ? <HiX className="h-8 w-8 sm:h-10 sm:w-10" /> : <HiMenu className="h-8 w-8 sm:h-10 sm:w-10" />}
        </button>
      </div>
      <nav className={`${isMenuOpen ? "flex" : "hidden"} flex-col md:flex md:flex-row md:items-center absolute top-full right-0 md:relative w-auto bg-black py-2 md:p-0 z-20`}>
        <Link to="/about" className="hover:text-gray-300 text-md sm:text-lg md:text-lg lg:text-xl xl:text-2xl px-4 py-2" onClick={() => setIsMenuOpen(false)}>About</Link>
        <Link to="/community" className="hover:text-gray-300 text-md sm:text-lg md:text-lg lg:text-xl xl:text-2xl px-4 py-2" onClick={() => setIsMenuOpen(false)}>Community</Link>
        <Link to="/blog" className="hover:text-gray-300 text-md sm:text-lg md:text-lg lg:text-xl xl:text-2xl px-4 py-2" onClick={() => setIsMenuOpen(false)}>Blog</Link>
        <Link to="/jobs" className="hover:text-gray-300 text-md sm:text-lg md:text-lg lg:text-xl xl:text-2xl px-4 py-2" onClick={() => setIsMenuOpen(false)}>Jobs</Link>
        {/* Include Sign Up and Donate buttons inside the menu for smaller screens */}
        <Link to="/signup" className="md:hidden hover:bg-violet-700 text-white text-md font-semibold px-4 py-2 rounded-lg mt-2" onClick={() => setIsMenuOpen(false)}>
          Sign Up
        </Link>
        <Link to="/donate" className="md:hidden hover:bg-violet-700 text-white text-md font-semibold px-4 py-2 rounded-lg mt-2" onClick={() => setIsMenuOpen(false)}>
          Donate
        </Link>
      </nav>
      {/* Ensure buttons remain visible outside the hamburger menu on larger screens */}
      <div className="hidden md:flex gap-2 sm:gap-3 md:gap-4">
        <Link to="/signup" className="bg-violet-500 hover:bg-violet-700 text-white text-sm sm:text-md md:text-lg lg:text-xl xl:text-2xl font-semibold py-3 px-5 rounded-lg">
          Sign Up
        </Link>
        <Link to="/donate" className="bg-violet-500 hover:bg-violet-700 text-white text-sm sm:text-md md:text-lg lg:text-xl xl:text-2xl font-semibold py-3 px-5 rounded-lg">
          Donate
        </Link>
      </div>
    </header>
  );
}

export default Header;